@import "../../../resources//less/newvariables.less";
@heading-font:2em;
@message-text:1.4em;

.new-thank-you {
  .round-tick-img {
    width: 85px;
    height: 85px;
  }
  .heading-text {
    font-size: @heading-font;
  }
  .message-text{
    font-size:@message-text;
    margin-bottom: 1em;
  }
}

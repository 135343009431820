@import "../../resources/less/newvariables.less";
.header-container-main {
  margin-bottom: 20px;
  .offer-plan {
    font-size: 16px;
    padding: 5px;
    border-radius: 8px;
    border: 2px solid #895a84;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 10%;
  }
  .title-wrapper {
    display: flex;
    justify-content: space-between;

    .title-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      .title-header {
        font-size: 30px;
        color: #895d85;
        font-weight: 400;
        // margin-bottom: 10px;
      }
      .client-button-wrapper {
        display: flex;
        gap: 20px;
        margin-top: 10px;
        .client-button {
          width: 100px;
          padding: 8px;
          border-radius: 7px;
          border: none;
          outline: none;
          font-size: 1rem;
          color: #fff;
          background-color: #895d85;
          border: 1px solid #895d85;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .title-right {
      display: flex;
      align-items: center;
      width: 40%;
      @media @mobile {
        width: 100%;
      }
    }
    .title-button {
      margin-top: 10px;
      height: 40px;
      width: 160px;
      padding: 2px 0px;
      border-radius: 0.5rem;
      font-size: 1rem;
      color: #885c85;
      background-color: #f7c98b;
      border: 1px solid #885c85;
      &:hover {
        cursor: pointer;
      }
    }
    .title-button-info-page {
      margin-top: -14px;
    }
  }
  .para-title-header {
    display: flex;
    align-items: center;
    padding: 0 20px 0 0;
    justify-content: space-between;
    // min-height: 40px;
    margin-bottom: 10px;
  }
  .select-div {
    display: flex;
    align-items: center;
    gap: 50px;
    .filter-payment-frequency {
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 5px;
        // display: block;
      }
    }
    p {
      margin: 0;
    }
    .capital-select {
      // margin-left: 20px;
      width: 200px;
    }
  }
  .back-button {
    color: #885c85;
    // margin-bottom: 15px;
    &:hover {
      cursor: pointer;
    }
  }
  .back-button-text {
    &:hover {
      cursor: pointer;
    }
  }
  .button-enabled {
    width: 145px;
    padding: 8px;
    border-radius: 7px;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #fff;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    cursor: pointer;
  }
}

@media only screen and (max-width: 900px) {
  .header-container-main {
    .para-title-header {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
    }
    .title-wrapper {
      flex-direction: column;
    }
  }
}
@media only screen and (max-width: 600px) {
  .header-container-main {
    // padding: 0 10px;
    .title-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title-left {
        display: flex;
        flex-direction: column;
        .title-header {
          font-size: 30px;
          // color: #000;
          font-weight: 400;
          // margin-bottom: 10px;
        }
      }
      .title-button {
        margin-top: 10px;
        height: 40px;
        width: 100%;
        padding: 2px 0px;
        border-radius: 0.5rem;
        font-size: 1rem;
        color: #885c85;
        background-color: #f7c98b;
        border: 1px solid #885c85;
        &:hover {
          cursor: pointer;
        }
      }
      .title-button-info-page {
        margin: 20px 0 20px 0;
      }
    }
    .para-title-header {
      span {
        margin-bottom: 10px;
      }
      // height: 100px;
    }
    .select-div {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      .capital-select {
        margin-left: 0;
      }
    }
    // padding: 0 20px;
  }
}

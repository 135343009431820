// @screen-sm-min = 576px, or you can choose another break point
@media only screen and (max-width: @screen-sm-min + 100px) {
  .ant-calendar-range {
    width: 276px;

    &-part {
      width: 100%;
    }

    &-right {
      float: left;
      border-top: 1px solid #e8e8e8;
    }
  }
}
@tablet: ~"only screen and (min-width: 601px) and (max-width: 901px)";
@mobile: ~"only screen and (max-width: 600px)";

// Define mixins

// Define styles
.side-menu {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: calc(100% - 64px);
  border-radius: 16px;
  background: #383837;
  float: left;
  position: fixed;
  z-index: 10;
  left: 32px;
  margin-top: 32px;
  font-size: 12px;
  font-family: BG-bold;
  overflow-y: auto;
  overflow-x: hidden;
  .logo-wrapper-sidebar {
    margin: 40px;
    margin-bottom: 10px;
    text-align: center;
    .logo-sidebar {
      width: 90px;
    }
  }
}
.logout {
  width: 85px;
  color: #fff;
  font-size: 16px;
  // position: absolute;
  // bottom: 44px;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  padding-bottom: 10px;

  img {
    vertical-align: text-bottom;
  }
}

.logo_micro {
  display: none;
}

.menu-trigger {
  display: none;
}

.navigation {
  margin-top: 15px;
  flex: 0.9;
  .buy-policy {
    width: 112px;
    height: 36px;
    margin: auto;
    border-radius: 6px;
    background-color: #b181aa;
    font-family: "BG-Bold";
    color: #fff;
    text-align: center;
    line-height: 34px;
    margin-top: 28px;
    font-size: 14px;
    cursor: pointer;

    img {
      display: inline !important;
    }

    span {
      line-height: 13px;
      width: 50px;
      display: inline-block;
      position: relative;
      top: 4px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    li {
      // margin: 20px 0;
      width: 100%;
      text-align: center;
      // flex: 1;
      padding: 0 7px;
      // margin: 0;
      list-style: none;
      margin-bottom: 20px;
      // margin: 19px 0 19px 20px;

      img {
        vertical-align: text-bottom;
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      img:first-child {
        // display: none;
      }

      a {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        padding: 8px 0;
        padding-left: 5px;
        border-radius: 5px;
        color: #fff;
        font-size: 13px;
        letter-spacing: 0.7px;
      }

      .nav-links-inactive {
        background-color: #5c5c5b;
      }
      // .disable-nav-links {
      //   pointer-events: auto;
      //   cursor: pointer;
      // }
      // .disable-nav-links {
      //   &:hover {
      //     cursor: not-allowed !important;
      //     pointer-events: none;
      //   }
      // }
      .nav-links-active {
        background: #b181aa !important;
      }
      &:hover {
        img:first-child {
          display: inline;
        }
        img:nth-of-type(2) {
          display: none;
        }

        a {
          // color: #f7cb8b;
          text-decoration: none;
        }
      }
      &:last-child {
        // position: absolute;
        // bottom: 70px;
      }
    }
    .comprar-online {
      // margin-top: 50px;
      .nav-links-active {
        background: #f7c98b !important;
      }
      a {
        span {
          color: #885c85;
        }
        img {
          filter: invert(44%) sepia(10%) saturate(1549%) hue-rotate(254deg)
            brightness(89%) contrast(89%);
        }
      }
    }

    .external-comprar-online {
      .nav-links-active {
        background: rgb(170, 206, 205) !important;
      }
    }

    li.active {
      img:first-child {
        display: inline;
      }
      img:nth-of-type(2) {
        display: none;
      }

      a {
        color: #f7cb8b;
        text-decoration: none;
      }
    }
  }
}
// @media screen and (max-height: 600px) {

// }
@media screen and (max-width: 900px) {
  .side-menu {
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0;
    width: 90px;
    height: 100%;
    left: 0;
    margin-top: 0;
    a {
      padding: 17px 0;
      display: none;
    }
    .logo {
      text-align: center;
      width: 40px;
      height: 20px;
      margin: 20px auto auto;
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      position: relative;
      // display: none;
    }

    .logo_micro {
      width: 33px;
      height: 23px;
      margin: auto;
      margin-top: 40px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      display: block;
    }

    // .logo {
    //   width: 33px;
    //   height: 11px;
    //   margin-top: 6px;
    // }
    .logo-wrapper-sidebar {
      display: none;
      text-align: center;
      margin: 80px 5px;
      .logo-sidebar {
        width: 60px;
      }
    }
    .navigation {
      margin-top: 0px;
    }

    ul {
      li {
        // margin: 19px auto 19px auto;
        margin: 17px auto 17px auto;
        text-align: center;
        padding: 0 17px;

        a {
          justify-content: center;
          padding: 10px 0 10px 0;
          display: block;

          span {
            display: none;
          }
          img {
            width: 22px;
            height: 22px;
            margin: 0;
          }
        }
        &:last-child {
          bottom: 80px;
        }
      }
    }

    .buy-policy {
      width: 36px;
      height: 36px;

      span {
        display: none;
      }
    }

    .logout {
      text-align: center;
      padding: 15px 0;

      img {
        width: 28px;
        height: 28px;
      }

      span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .side-menu {
    display: unset;
    background-repeat: repeat-x;
    background-color: #383837;
    width: 100%;
    height: 95px;
    margin-top: 0;
    .navigation {
      margin-top: 80px;
    }
    ul,
    .buy-policy,
    .logout,
    .logo_micro {
      display: none;
    }

    .logo {
      margin-top: 35px !important;
      width: 75px;
      height: 24px;
      margin: 0;
      margin-left: 15px;
      float: left;
    }

    .menu-trigger {
      display: block;
      margin-top: 35px;
      width: 24px;
      height: 24px;
      margin-right: 24px;
      float: right;
    }
    .logo-wrapper-sidebar {
      display: none;
    }
  }
  .side-menu ul {
    margin-left: 0;
  }
  .side-menu a {
    text-align: center;
    display: block;
  }
  .side-menu a span {
    display: inline-block;
    font-size: 16px;
  }
  .side-menu ul,
  .side-menu .buy-policy,
  .side-menu .logout {
    display: block;
  }

  .navigation,
  .side-menu {
    height: 100%;
  }
  .side-menu ul li img {
    width: 22px;
    height: 22px;
    vertical-align: text-bottom;
    position: relative;
    top: 0px;
  }
  .side-menu .buy-policy {
    width: 115px;
    margin-left: 24px;
    margin-top: 16px;

    span {
      display: inline-block;
      line-height: 13px;
    }

    img {
      top: -2px;
      left: -3px;
    }
  }
  // .side-menu ul {
  //   margin-left: 24px;
  // }
  .side-menu ul li {
    text-align: left;

    span {
      padding-left: 8px;
    }
  }
  .side-menu .logout {
    margin: 0;
    margin-left: 24px;
    text-align: left;
    width: 100%;
    // position: absolute;
    bottom: 50px;
  }
  .side-menu .logout span {
    display: inline;
    font-size: 16px;
    padding-left: 8px;
  }
  .side-menu .logout img {
    width: 22px;
    height: 22px;
  }
  .navigation {
    margin-top: 80px;
  }
  .hidden-sm {
    display: none !important;
  }
  .hidden-list {
    height: 95px !important;
  }
  .side-menu ul li a span {
    display: inline-block;
  }
}

@steps-prefix-cls: ~'@{ant-prefix}-steps';
@process-icon-color: @secondary-color;
@process-tail-height: 2px;
@wait-icon-color: @border-color-split;
@finish-tail-color: @secondary-color;

@steps-dot-size: 24px;
@steps-current-dot-size: 25px;
@steps-desciption-max-width: 100%;


.@{steps-prefix-cls}-item {
  // last item is the same width as the other items
  &:last-child {
    flex: 1 1;
  }
}

@import 'progress-dot';

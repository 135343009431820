.iframe-redirect-success {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .tickIcon {
    width: 80px;
    height: auto;
  }
  .heading {
    text-align: center;
    font-size: 20px;
  }
  .text {
    text-align: center;
    font-size: 16px;
  }
}

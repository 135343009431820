.@{steps-prefix-cls}-dot,
.@{steps-prefix-cls}-dot.@{steps-prefix-cls}-small {
  .@{steps-prefix-cls}-item {
    &-tail {
      top: @steps-dot-size / 2 - @process-tail-height;

      &::after {
        height: @process-tail-height;
        margin-left: @steps-dot-size / 2;
      }
    }

    &-icon {
      width: auto;
      height: @steps-dot-size;
      margin-right: 0;
      margin-left: 0;
      display: block;
      position: relative;

      > .@{steps-prefix-cls}-icon {
        position: static;
      }

      .@{steps-prefix-cls}-icon-dot {
        width: @steps-dot-size;
        height: @steps-dot-size;
        margin: auto;
        position: absolute;
        right: 0;
        left: 0;

        &::before{
          content: '';
          border: 5px solid @white;
          border-radius: 50%;
          position: absolute;
          top: 2px;
          right: 2px;
          bottom: 2px;
          left: 2px;
        }

        &::after {
          display: none;
        }
      }
    }

    &-process .@{steps-prefix-cls}-item-icon {
      width: auto;

      .@{steps-prefix-cls}-icon-dot {
        top: auto;
      }
    }
  }
}

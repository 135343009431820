@import "./newvariables.less";
#details-form {
  .ant-collapse {
    //   box-sizing: border-box;
    //   margin: 0;
    //   padding: 0;
    //   color: rgba(0, 0, 0, 0.65);
    //   line-height: 1.5;
    //   list-style: none;
    font-size: 16px;
    background-color: #f7f7f7;
    border-radius: 4px;
  }
  .panel-class {
    background-color: #fff;
    border-radius: 8px !important;
    box-shadow: -3px -3px 20px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #d9d9d9;
    margin-bottom: 20px;
  }
  .ant-collapse-item {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 0 10px;
  }
  .ant-collapse-header {
    border-radius: 4px;

    position: relative;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 2px solid #f4f5f9;
  }
  .question-heading {
    font-size: @question-fontSize;
  }
  .answer-heading {
    font-size: @answer-fontSize;
  }

  .sub-heading {
    font-size: 22px;
    color: #895d85;
  }
  .sub-description {
    font-size: 16px;
  }
  .input-box-half {
    width: 48% ;
    @media @mobile {
      width: 100%;
    }
  }

  // @media only screen and (max-width: 600px) {
  //   .questionniare-container {
  //     padding: 0 10px;
  //     .questionnaire-form {
  //       .questionnaire-heading {
  //         font-size: 24px;
  //         .number {
  //           width: 32px;
  //           height: 28px;
  //           line-height: 28px;
  //         }
  //       }
  //     }

  //     .collapse-journey {
  //       // margin-left: 20px;
  //       // padding: 0 10px;
  //       .panel-class {
  //         .business-life-question {
  //           display: flex;
  //           flex-direction: column;
  //           margin-bottom: 20px;
  //           .field-array-questions {
  //             display: flex;
  //             flex-direction: column;
  //             // flex: 1;
  //             // gap: 5%;
  //           }
  //         }
  //         .input-box {
  //           width: 100% !important;
  //         }
  //         .div-wrapping-component-business-life {
  //           margin-bottom: 0;
  //         }
  //         .editBtn {
  //           padding: 0px 10px;
  //         }
  //         .text {
  //           font-size: 22px;
  //           padding: 8px;
  //         }

  //         .input-box-select,
  //         .input-box-select-business-life {
  //           width: 100% !important;
  //         }
  //         .selectable-tags-div {
  //           .selectable-ant-tags {
  //             width: 100%;
  //             margin-right: 0px;
  //           }
  //         }
  //         .declaration-consents {
  //           margin-bottom: 0;
  //           display: block;
  //         }
  //         .saved-details {
  //           border-top: 1px solid @primary-color;
  //           margin-top: 10px;
  //           padding-top: 10px;
  //           .business-life-question-heading {
  //             display: flex;
  //             flex-direction: column;
  //             gap: 0px;
  //             border-bottom: 2px solid rgb(244, 245, 249);
  //             margin-top: 10px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}

.public-route {

  .ant-steps-dot .ant-steps-item-tail,
  .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    top: 10px;
    margin: 0 !important;
    margin-left: 20px !important;
    // width: 110% !important;
    // width: unset !important;
  }
  .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-size: 12px;
    font-weight: 400;
    color: var(--secondary-color-theme) !important;
  }
  .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-size: 12px;
    font-weight: 400;
    color: var(--secondary-color-theme) !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-size: 12px;
    font-weight: 400;
    color: var(--secondary-color-theme) !important;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    background-color: var(--secondary-color-theme) !important;
  }
  .ant-steps-item-process
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: var(--secondary-color-theme) !important;
  }
  .ant-steps-item-finish
    .ant-steps-item-icon
    > .ant-steps-icon
    .ant-steps-icon-dot {
    background: var(--secondary-color-theme) !important;
  }
}

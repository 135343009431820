@import "../../resources/less/newvariables.less";

@primary: #895d85;
@secondary: #aacecd;

::-moz-selection {
  background-color: var(--secondary-color-theme, @primary) !important;
  color: #ffffff !important;
  text-shadow: none !important;
}

::selection {
  background-color: var(--secondary-color-theme, @primary) !important;
  color: #ffffff !important;
  text-shadow: none !important;
}

.main-container {
  background-color: #f7f7f7;
  width: 100%;
  min-height: 100vh;
  padding: 0px 32px 32px;
  position: absolute;
  @media (max-width: 890px) {
    padding: 0px 8px 32px;
  }
}
.content-block {
  float: left;
  padding: 31px 20px 0 212px;
  margin-bottom: 31px;
  width: 100%;
  background-color: #f7f7f7;
  min-height: 1000px;
  padding-bottom: 4rem;
  background: url("../../images/confetti.svg");
  background-repeat: repeat-x;
  background-repeat: repeat-x;
}
.confetti-container {
  background-repeat: repeat-x;
  padding-top: 30px;
}
main {
  min-height: calc(100% - 188px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-spinner {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}
// .cover-spinner {
//   width: 100%;
//   height: 100%;
//   margin: 0 auto;
//   background-color: white;
//   opacity: 0.5;
//   top: 0;
//   left: 0;
//   z-index: 99;
//   position: absolute;
//   .spinner-wrapper {
//     display: flex;
//     height: 100vh;
//     .spinner-login {
//       display: flex;
//       flex: 1;
//       justify-content: center;
//       align-items: center;
//     }
//   }
// }
.ant-modal-body {
  padding: 0 !important;
}
.ant-steps {
  width: 100% !important;
  @media @tablet, @mobile {
    width: 100% !important;
  }

  .ant-steps-item {
    display: flex !important;
    justify-content: flex-start !important;
    &:last-child {
      flex: unset !important;
    }
  }
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 10px;
  margin: 0 !important;
  margin-left: 20px !important;
  // width: 110% !important;
  // width: unset !important;
}
.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 12px;
  font-weight: 400;
  color: @secondary-color !important;
}
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 12px;
  font-weight: 400;
  color: @secondary-color !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 12px;
  font-weight: 400;
  color: @secondary-color !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: @secondary-color !important;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: @secondary-color !important;
}
.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: @secondary-color !important;
}

@media only screen and (max-width: 900px) {
  .content-block {
    padding: 31px 0 50px 122px;
    width: calc(100% - 30px);
    .cards {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: scroll;
      .title {
        white-space: normal;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .content-block {
    padding: 116px 0px 70px 0px;
    width: 100%;
    .cards {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: scroll;
      .title {
        white-space: normal;
      }
    }
  }
}

.notification-info {
  background-color: #aacecd !important;
  .notification-title {
    font-weight: 700;
  }
}

.notification-danger {
  background-color: rgba(255, 187, 97) !important;
  border-left: 8px solid #895d85 !important;

  .timer {
    background-color: #895d85 !important;
  }

  .notification-title {
    color: #895d85 !important;
  }

  .notification-message {
    color: #895d85 !important;
    font-weight: bold;
  }
}

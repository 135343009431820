@tablet: ~"only screen and (max-width: 901px)";
@mobile: ~"only screen and (max-width: 600px)";

.ant-input {
  height: 40px !important;
}
.error-div-active {
  background: #ffe5e5;
  padding: 10px;
  .formik-errors {
    margin-top: 5px;
    color: red;
  }
}
.tab-text {
  margin-bottom: 10px;
}
.file-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .file-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b9b9c3;
    background: #e9e9ed;
    padding: 10px 20px;
    width: 130px;
    border-radius: 8px;
    height: 40px;
    &:hover {
      cursor: pointer;
    }
  }
  .file-name {
    position: absolute;
    color: #895d85;
    font-size: 14px;
    // bottom: 0;
    bottom: -20px;
  }
}
.hyperlink {
  color: #895d85;
  &:hover {
    cursor: pointer;
  }
}
.tab-text-light {
  font-family: "BG-Light";
  margin-bottom: 10px;
}
.div-wrapping-component {
  margin-bottom: 20px;
  margin-top: 10px;
  // input {
  //   width: 100%;
  // }
  // // span {
  // //   width: 100%;
  // // }
  // .input-box {
  //   width: 100%;
  // }
}
.child-overlay-ui {
  border-left: 3px solid var(--secondary-color-theme);
  padding-left: 20px;
}
.selectable-tags-div-flow-wrap {
  flex-flow: row wrap;
  @media @mobile {
    flex-flow: unset;
  }
}
.selectable-tags-div {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media @mobile {
    flex-direction: column;
    gap: 0px;
  }
  // flex-flow: row wrap;
  .tags-active-travel {
    background-color: var(--secondary-color-theme);
    border: 2px solid var(--secondary-color-theme);
    &:hover {
      border: 2px solid var(--secondary-color-theme);
    }
  }
  .tags-active {
    background-color: #aacecd;
    border: 2px solid #aacecd;
    &:hover {
      border: 2px solid #aacecd;
    }
  }
  .tags-inactive {
    background-color: white;
    border: 2px solid #ddd;
    &:hover {
      border: 2px solid #ddd;
    }
  }

  .selectable-ant-tags {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: auto;
    min-width: 120px;
    // background-color: white;
    // margin-right: 20px;
    font-size: 1rem;
    border-radius: 6px;
    height: 100%;
    margin-bottom: 10px;
    font-weight: bold;
    border: 2px solid #ddd;
    text-align: center;
    padding: 5px 20px;
    min-height: 42px;
    color: #000;
    &:hover {
      // border: 2px solid var(--secondary-color-theme);
      cursor: pointer;
    }
    p {
      margin: 0 !important;
    }

    @media @mobile {
      width: 100%;
      margin-right: 0;
    }
  }
}

.selectable-tag-card-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
  column-gap: 15px;
  grid-row-gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;
  @media @mobile {
    display: flex;
    flex-direction: column;
  }

  .selectable-tag-card {
    background-color: white;
    border-radius: 6px;
    border: 2px solid #ddd;
    border-color: rgb(221, 221, 221);
    cursor: pointer;
    // height: 175px;
    .tag-card-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 45px;
      font-weight: bold;
      font-size: 1rem;
      color: black;
    }
    .tag-card-body {
      flex-direction: column;
      padding: 10px;
      border-top: 1px solid #ddd;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .selectable-tag-card-active {
    border-color: #aacecd;
    .tag-card-title-active {
      background: #aacecd;
    }
  }
}

.input-box {
  width: 200px !important ;
  -moz-appearance: textfield !important;
  -webkit-appearance: none !important;
  @media @mobile {
    width: 100% !important;
  }
}
.input-box-full {
  width: 100% !important;
  -moz-appearance: textfield !important;
  -webkit-appearance: none !important;
}
.input-box-range {
  // height: 40px;
  width: 300px;
  @media @mobile {
    width: 100%;
  }
}
.input-box-select {
  // height: 40px;
  width: 200px;
  @media @mobile {
    width: 100%;
  }
}
.input-box-country {
  width: 300px !important;
  @media @mobile {
    width: 100%;
  }
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

input[type="checkbox"] + span {
  cursor: pointer;
  font: 16px sans-serif;
  color: black;
}

input[type="checkbox"] + span:before {
  content: "";
  border: 1px solid grey;
  border-radius: 3px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  margin-top: 0.5em;
  vertical-align: -2px;
}
.checkbox-options {
  input[type="checkbox"]:checked + span:before {
    background-image: url("../../images/tick-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-size: 10px;
    border-radius: 2px;
    background-color: #aacecd;
    color: white;
  }
}
.checkbox-options-journey {
  flex-basis: 30%;
  input[type="checkbox"]:checked + span:before {
    background-image: url("../../images/tick-icon.png");
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-size: 10px;
    border-radius: 2px;
    background-color: var(--secondary-color-theme);
    color: white;
  }
}

.input-flex-wrapper-area {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @media @mobile {
    flex-direction: column;
    gap: 0px;
  }
  .div-wrapping-component {
    flex: 1;
  }
}

.declaration-consents {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  .label-text {
    font-size: 15px;
    margin-bottom: 0;
  }
  .label-text-large {
    font-size: 16px;
    margin-bottom: 0;
  }
}

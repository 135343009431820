@import "../../resources/less/newvariables.less";
@tablet: ~"only screen and (min-width: 601px) and (max-width: 1100px)";
@mobile: ~"only screen and (max-width: 600px)";
#pricing-details {
  input[type="checkbox"] + span:before {
    width: 18px !important;
    height: 18px !important;
  }
  // color: #895d85;
  color: rgba(0, 0, 0, 0.65);
  width: 60%;
  @media @tablet, @mobile {
    width: 100%;
  }
  .heading {
    margin-bottom: 20px;
    .sub-heading {
      font-size: 20px;
      color: #895d85;
    }
    .sub-description {
      font-size: 16px;
    }
  }

  .pricing-selection-box {
    padding: 20px;
    border-radius: 8px;
    // border: 2px solid #895d85;
    background-color: white;
    box-shadow: -3px -3px 20px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 50px;
    @media @mobile {
      padding: 20px 10px;
    }
    .desktop-version {
      .module-info {
        display: flex;
        gap: 20px;
        .column-description {
          flex: 1;
          padding-right: 20px;
        }
        .column-pricing {
          .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 30px;
            @media @mobile {
              height: unset;
            }
          }
        }
        .subscribe-package {
          display: flex;
          flex-direction: column;
          // justify-content: center;
          // align-items: center;
          .subscribe-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
        .module-name {
          font-size: 18px;
          font-weight: bold;
        }
        ul {
          // height: 20px;
          list-style: none;
          padding-left: 20px;
          .wrapper {
            display: flex;
            align-items: center;
            height: 30px;
            @media @mobile {
              height: unset;
            }
            li {
              margin-right: 30px;
              white-space: nowrap;
              @media @mobile {
                white-space: normal;
              }
            }
            div {
              height: 1px;
              background-color: #895d85;
              width: 100%;
            }
          }
        }
      }
      @media @mobile {
        display: none;
      }
    }
    .mobile-version {
      display: none;
      @media @mobile {
        display: block;
      }
      .mobile-container {
        .mobile-module {
          margin-bottom: 30px;
          padding-bottom: 10px;
          border-bottom: 1px solid #895d85;
        }
        .modules-mobile-heading {
          display: flex;
          gap: 20px;
          .name {
            flex: 1;
          }
          .price {
            flex: 0.4;
          }
        }
        .modules-mobile-info {
          flex: 1;
          display: flex;
          flex-direction: column;

          ul {
            list-style: none;
            padding-left: 10px;
          }
        }
        .modules-mobile-price {
          flex: 0.5;
        }
      }
    }
  }
  .IBAN-ques {
    width: 50%;
    @media @mobile, @tablet {
      width: 100%;
    }
  }

  .info-box {
    // padding: 20px 0;
    border-radius: 8px;
    font-size: 16px;
    // border: 2px solid #895d85;
    .heading {
      margin-bottom: 30px;
    }
    .sub-desc {
      font-weight: bold;
    }
    .module-sub {
      margin-bottom: 30px;
    }
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
  }

  .ant-collapse {
    font-size: 16px;
    background-color: #f7f7f7;
    border-radius: 4px;
  }
  .panel-class {
    background-color: #fff;
    border-radius: 8px !important;
    box-shadow: -3px -3px 20px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #d9d9d9;
    margin-bottom: 20px;
  }
  .ant-collapse-item {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 0 10px;
  }
  .ant-collapse-header {
    border-radius: 4px;

    position: relative;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 2px solid #f4f5f9;
  }
  .question-heading {
    font-size: @question-fontSize;
  }
  .answer-heading {
    font-size: @answer-fontSize;
  }

  .sub-heading {
    font-size: 22px;
    // color: #895d85;
  }
  .sub-description {
    font-size: 16px;
  }
  .badge-and-text {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .inner-badge-text {
      display: flex;
      align-items: center;
      .badge {
        width: 30px;
        height: 30px;
        background-color: #895d85;
        text-align: center;
        line-height: 30px;
        color: white;
        border-radius: 50px;
        font-size: 15px;
      }
      .text {
        padding: 10px;
        color: #895d85;
        font-size: 1.2rem;
      }
    }
    .editBtn {
      user-select: none;
      touch-action: manipulation;
      height: 32px;
      padding: 0 15px;
      font-size: 14px;
      border-radius: 4px;
      color: rgba(0, 0, 0, 0.65);
      background-color: #fff;
      border-color: #d9d9d9;
      display: flex;
      text-align: center;
      align-items: center;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-weight: 400;
      white-space: nowrap;
      line-height: 1.499;
      position: relative;
      &:hover {
        cursor: pointer;
        border: 1px solid #895d85;
      }
    }
  }
  .heading-collapse {
    padding: 12px !important;
    background: white;
  }
}

@import '../../resources/less/newvariables.less';
@tablet: ~"only screen and (min-width: 601px) and (max-width: 901px)";
@mobile: ~"only screen and (max-width: 600px)";
.footer {
  transition: height 300ms;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  font-size: 0.8rem;
  // height: 110px;

  .footer-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .footer-wrapper-iob {
      display: flex;
    }

    .footer-wrapper-col {
      margin-top: 5px;
    }

    .main-text {
      color: rgba(0, 0, 0, 0.65);
      font-size: 15px;

      .arrow-img-up {
        width: 15px;
        height: 12px;
      }

      .arrow-img-down {
        width: 15px;
        height: 11px;
      }

      &:hover {
        cursor: pointer;
        color: @primary-color;
      }
    }
  }

  .content-block-footer {
    background-color: white;
  }

  .footer-flag {
    height: 17px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .footer-logo {
    width: 60px;
    margin-right: 10px;
    height: auto;
  }

  .footer-icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
  }

  .hyperlinks-wrapper {
    padding: 10px;
    display: flex;
    border: 1px #dddddd solid;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90%;
    @media @mobile {
      flex-direction: column;
      text-decoration: none;
      border-radius: 5px;
      margin-bottom: 5px;
    }
    span {
      text-align: center;
      flex: 25%;
      margin-bottom: 10px;

      a {
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        color: rgba(0, 0, 0, 0.65);

        &:hover {
          color: @primary-color;
        }
      }
    }
  }

  .hyperlinks-end-div {
    height: 10px;
  }

  &.footer-increased {
    height: 200px;
    position: fixed;
  }

}

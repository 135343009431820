.spinner {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: white;
  opacity: 0.8;
  top: 0;
  left: 0;
  z-index: 99;
  position: fixed;
}

.spinner-wrapper {
  display: flex;
  height: 100vh;
}
.spinner-login {
  display: flex !important;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.@{tooltip-prefix-cls} {
  // Wrapper for the tooltip content
  &-inner{
    padding: 8px 10px;
    color: @black;
    text-align: center;
    font-size: 1rem;
    line-height: 1.4rem;
    border: 2px solid @secondary-color;
  }

  // Arrows
  &-arrow {
    &::before {
      background-color: @tooltip-arrow-color;
    }
  }
}

.partner-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  height: 45px;
  color: #fff;
  justify-content: center;
  background: #895a84;
  border-radius: 7px;
  outline: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
}
.editBtn {
  display: flex !important;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border-color: #d9d9d9;
  display: flex;
  text-align: center;
  align-items: center;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 400;
  white-space: nowrap;
  line-height: 1.499;
  position: relative;
  &:hover {
    cursor: pointer;
    border: 1px solid #895d85;
    color: #895d85;
  }
}

/*
 * Container
 */
.container {
  width: 100%;
  padding-right: @container-padding-x;
  padding-left: @container-padding-x;
  margin-right: auto;
  margin-left: auto;
}

.page__wrapper {
  display: grid;
  grid-row-gap: 25px;
  justify-items: start;
  align-items: flex-start;
  text-align: left;
}

@media (min-width: @screen-sm) {
  .container {
    max-width: @container-max-width-sm;
  }
}

@media (min-width: @screen-md) {
  .container {
    max-width: @container-max-width-md;
  }
}

@media (min-width: @screen-lg) {
  .container {
    max-width: @container-max-width-lg;
  }
}

@media (min-width: @screen-xl) {
  .container {
    max-width: @container-max-width-xl;
  }
}

// @media (min-width: @screen-xxl) {
//   .container {
//     max-width: @container-max-width-xxl;
//   }
// }
